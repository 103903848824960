<template>
  <section class="account">
    <div class="container">
      <h2 class="account__title">Вход в личный кабинет</h2>
      <h2 v-if="isLoading">Loading user...</h2>
      <div class="account__form form">
        <div class="form__signUp">
          <h2>Создание нового аккаунта:</h2>
          <form @submit.prevent="sendRequest('signup')">
            <input
              type="text"
              name="name"
              placeholder="Имя"
              v-model.trim="userData.firstname"
              @input="clearErrors"
              required
            />
            <input
              type="text"
              name="last-name"
              placeholder="Фамилия"
              v-model="userData.lastname"
              @input="clearErrors"
              required
            />
            <input
              type="password"
              name="password1"
              autocomplete="on"
              placeholder="Пароль"
              v-model.trim="userData.password"
              @input="clearErrors"
              required
            />
            <input
              type="password"
              name="password2"
              autocomplete="on"
              placeholder="Подтверждение пароля"
              v-model.trim="userData.confirm"
              @input="clearErrors"
              @blur="validationPassword"
              required
            />
            <p class="error-field" v-show="notMatchPassword">
              Пароли не совпадают!
            </p>
            <div class="signUp__contact">
              <div class="signUp__group">
                <input
                  type="tel"
                  name="phone"
                  placeholder="Телефон"
                  v-model.trim="userData.telephone"
                  @input="clearErrors"
                  required
                />
              </div>
              <div class="signUp__group">
                <input
                  type="email"
                  name="e-mail"
                  placeholder="E-mail"
                  v-model="userData.email"
                  @input="clearErrors"
                  required
                />
              </div>
            </div>
            <div class="form__address">
              <select
                id="country"
                name="country"
                v-model="userData.country_id"
                required
              >
                <option value="null" disabled selected>Выберите страну</option>
                <option value="176">Российская Федерация</option>
                <option value="109">Казахстан</option>
                <option value="115">Киргизия (Кыргызстан)</option>
                <option value="226">Узбекистан</option>
              </select>
              <input
                type="text"
                name="city"
                placeholder="Город"
                v-model.trim="userData.city"
                @input="clearErrors"
                required
              />
              <input
                type="text"
                name="street"
                placeholder="Улица"
                v-model.trim="userData.address_1"
                @input="clearErrors"
                required
              />
              <div class="address__build-apartment">
                <div class="signUp__group">
                  <input
                    type="text"
                    name="building"
                    placeholder="Дом"
                    v-model.trim="userAddress.building"
                    @input="clearErrors"
                    required
                  />
                </div>
                <div class="signUp__group">
                  <input
                    type="number"
                    name="apartment"
                    placeholder="Квартира"
                    v-model.trim="userAddress.apartment"
                    @input="clearErrors"
                    required
                  />
                </div>
              </div>
            </div>
            <div class="error-field" v-if="!formValid">
              <p>Пожалуйста, заполните все поля!</p>
            </div>
            <button name="btn1" :disabled="isAuthenticated">Далее</button>
          </form>
        </div>

        <div class="form__signIn">
          <h2>Войти</h2>
          <form @submit.prevent="sendRequest('login')">
            <input
              type="email"
              name="e-mail2"
              placeholder="E-mail"
              v-model="userAuth.email"
              @input="clearErrors"
              required
            />
            <input
              type="password"
              name="last-name"
              placeholder="Пароль"
              v-model="userAuth.password"
              @input="clearErrors"
              required
            />
            <router-link to="/fogotten"> Забыли пароль?</router-link>
            <button name="btn2">Войти</button>
          </form>
          <div class="error-field" v-if="!authValid">
            <p>Пожалуйста, введите ваш email и пароль</p>
          </div>
        </div>
        <div class="error-field" v-if="getError.length > 0">
          <p v-for="(err, i) in getError" :key="i">{{ err }}</p>
        </div>
      </div>
      <!-- <h3>
        © Copyright 2020-2021 ООО “RIZA HERB” Все права защищены. RIZA HERB®.
      </h3> -->
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      userData: {
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        confirm: "",
        telephone: "",
        fax: "1-541-754-3010",
        company_id: "string",
        company: "string",
        city: "",
        address_1: "",
        address_2: "",
        country_id: null,
        postcode: "3333",
        zone_id: 1256,
        tax_id: 1,
        customer_group_id: 1,
        agree: 1,
        custom_field: {
          account: {
            1: "+364545454",
          },
          address: {
            4: "+364545454",
          },
        },
      },
      userAuth: {
        email: "",
        password: "",
      },
      userAddress: {
        building: "",
        apartment: "",
      },
      isLoading: false,
      formValid: true,
      authValid: true,
      notMatchPassword: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["getError", "isAuthenticated"]),
    fullAddress() {
      if (this.userAddress.building && this.userAddress.apartment) {
        console.log(this.userAddress.building, this.userAddress.apartment);
        return this.userAddress.building + "/-/" + this.userAddress.apartment;
      }
      return "";
    },
  },
  methods: {
    ...mapActions("auth", ["signup", "login", "clearErrors"]),

    validationForm() {
      this.formValid = true;
      if (
        !this.userData.firstname ||
        !this.userData.lastname ||
        !this.userData.password ||
        !this.userData.confirm ||
        !this.userData.telephone ||
        !this.userData.email ||
        !this.userData.country_id ||
        !this.userData.city ||
        !this.userData.address_1
      ) {
        this.formValid = false;
      }
    },
    validationPassword() {
      this.notMatchPassword = false;
      const inp1 = document.querySelector('input[name="password1"]');
      const inp2 = document.querySelector('input[name="password2"]');
      inp1.classList.remove("invalid");
      inp2.classList.remove("invalid");
      if (this.userData.password !== this.userData.confirm) {
        this.notMatchPassword = true;
        inp1.classList.add("invalid");
        inp2.classList.add("invalid");
      }
    },
    validationAuth() {
      if (!this.userAuth.email || !this.userAuth.password) {
        this.authValid = false;
      }
    },

    async sendRequest(mode) {
      if (mode === "signup") {
        this.formValid = true;
        // This method stick fields of building and apartaments
        this.userData.address_2 = this.fullAddress;
        console.log(this.userData);
        // This method check filled fields
        this.validationForm();

        if (!this.formValid) {
          return;
        }

        this.isLoading = true;

        await this.signup(this.userData);

        this.isLoading = false;
      }

      if (mode === "login") {
        this.authValid = true;
        this.validationAuth();

        if (!this.authValid) {
          return;
        }

        this.isLoading = true;

        await this.login(this.userAuth);

        this.isLoading = false;
      }

      if (this.getError.length == 0) {
        this.$router.push("/home")
      }
    },
    defineCountry() {
      switch (this.getGeo) {
        case "KZ":
          return (this.userData.country_id = "109");
        case "KG":
          return (this.userData.country_id = "115");
        case "UZ":
          return (this.userData.country_id = "226");
        default:
          return (this.userData.country_id = "176");
      }
    },
  },
  mounted() {
    this.defineCountry()
  }
};
</script>

<style scoped>
.account {
  padding: 50px 0;
}

.account h2 {
  text-align: center;
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 24px;
}
.account__form {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1100px;
  border: 1px solid #75b051;
  border-radius: 8px;
  padding: 53px 60px 100px 53px;
}
.account__form h2 {
  text-align: center;
  padding-bottom: 20px;
}
.account .form__signUp {
  border-right: 1px solid #75b051;
  padding-right: 57px;
  flex: 1 1 50%;
}
.account .form__signIn {
  padding-left: 57px;
  text-align: center;
  flex: 1 1 50%;
}

.account form a {
  color: #75b051;
}
.account form input,
.form__address > select,
.account form input[name="e-mail2"] {
  text-align: center;
  border: 1px solid #75b051;
  border-radius: 5px;
  margin-bottom: 8px;
  width: 100%;
}

.signUp__contact,
.address__build-apartment {
  display: flex;
  width: 100%;
  column-gap: 10px;
  flex-wrap: nowrap;
  /* Test add flex priority, resolution above 1200 change width of the fields */
  /* flex: 1 1 50%;  */
}
.signUp__group {
  width: 50%;
}
.account form input[name="phone"],
.form__address > select,
.form__address > input,
.address__build-apartment .signUp__group > input,
.account form input[name="e-mail"] {
  padding: 9px 0;
}
.form__address > select {
  color: #757575;
  background-color: inherit;
}

.account form > input {
  display: block;
  padding: 9px 60px;
}

.invalid,
select:focus:required:invalid,
input:focus:required:invalid {
  border: 2px solid red !important;
}

.account form button {
  display: block;
  text-align: center;
  width: 100%;
  padding: 7px 100px;
  background-color: #75b051;
  color: #ffffff;
  border-radius: 5px;
}
.account form button[name="btn1"] {
  margin-top: 18px;
}
.account form button[name="btn1"]:disabled {
  margin-top: 18px;
  background-color: grey;
}
.account form button[name="btn2"] {
  margin-top: 49px;
}
.account h3 {
  padding-top: 53px;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #606060;
  padding-bottom: 53px;
}

.error-field {
  max-width: 300px;
  text-align: center;
  margin: 20px auto;
  font-size: 15px;
  font-weight: 600;
  color: red;
}

@media (max-width: 990px) {
  .form {
    display: flex;
    justify-content: center;
    padding: 0;
    flex-direction: column;
    max-width: 400px;
  }
  .form__signIn,
  .form__signUp {
    padding: 0 !important;
    max-width: 100%;
  }
  .account .form__signIn {
    margin-top: 20px;
  }

  .account__form,
  .account .form__signUp {
    border: none;
  }
}

/* .form .error-field {
  font-size: 15px;
  font-weight: 600;
  color: red;
  text-align: center;
  margin-top: 20px;
} */
</style>
